import React from 'react';

import { Box, Typography } from '@mui/material';

import { BankOffer } from '@relif/backend';
import { dateFormat } from 'services/format/dateFormat';
import { captureUserEvent } from 'utils/posthogUtils';

import TermOptionCard from './TermOptionCard';

const BankOfferOptionDetails = ({
  bankOffer,
  options,
  selectedTerm,
  setInterested,
}: {
  bankOffer: BankOffer;
  options: BankOffer['options'];
  selectedTerm: number | null;
  setInterested: (id: number, months: number) => void;
}) => {
  return (
    <Box>
      <Typography
        variant="body1"
        sx={{
          width: '100%',
          fontSize: 18,
          fontWeight: 600,
          color: 'primary.main',
          textAlign: 'left',
        }}
      >
        Elige una opción de cuotas
      </Typography>
      {options.map((option) => (
        <TermOptionCard
          key={option.term}
          isSelected={selectedTerm === option.term}
          boldTitle={`${option.term} `}
          title="cuotas de"
          amount={option.monthlyPayment || 0}
          onClick={() => {
            captureUserEvent('BankOfferFlow: User confirmed interest in a Refinance offer option', {
              bank: bankOffer.bank,
              bankOfferUuid: bankOffer.uuid,
              bankOfferOption: option.term,
            });
            setInterested(bankOffer.id, option.term);
          }}
        />
      ))}
      <Typography
        variant="body1"
        sx={{
          width: '100%',
          fontSize: 14,
          fontWeight: 400,
          color: 'primary.main',
          textAlign: 'left',
        }}
      >
        * Montos aproximados. Podrían variar al hablar con el banco.
      </Typography>

      <ul>
        <li>
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 16,
              fontWeight: 400,
              color: 'primary.main',
              textAlign: 'left',
            }}
          >
            Este monto puede ser mayor al total de tus deudas. Si lo deseas, puedes solicitar un monto menor.
          </Typography>
        </li>
        {bankOffer.description && (
          <li>
            <Typography
              variant="body1"
              sx={{
                width: '100%',
                fontSize: 16,
                fontWeight: 400,
                color: 'primary.main',
                textAlign: 'left',
              }}
            >
              {bankOffer.description}
            </Typography>
          </li>
        )}
        {bankOffer.firstPaymentDate && (
          <li>
            <Typography
              variant="body1"
              sx={{
                width: '100%',
                fontSize: 16,
                fontWeight: 400,
                color: 'primary.main',
                textAlign: 'left',
              }}
            >
              La fecha del primer pago sería el: {dateFormat(new Date(bankOffer.firstPaymentDate))}
            </Typography>
          </li>
        )}
      </ul>
    </Box>
  );
};

export default BankOfferOptionDetails;
