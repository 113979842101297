import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, Grid, Typography } from '@mui/material';

import RelifLogo from 'assets/images/logo.webp';
import CajaLosAndesLogo from 'assets/images/tapp/caja_los_andes_logo.svg';
import PlayButtonIcon from 'assets/images/tapp/play_button.svg';
import { checkIfTokenIsValid } from 'authStorage';
import FinancialAdvisorAuthModal from 'components/common/FinancialAdvisorAuthModal';
import useAccessToken from 'hooks/useAccessToken';
import useDebtFlow from 'hooks/useDebtFlow';
import useIsMobile from 'hooks/useIsMobile';
import { useLazyGetLastCMFRequestQuery } from 'services/api/floid.api';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';

import styles from './CLAVirtualAdvisorDashboard.module.scss';
import ChatButton from './components/ChatButton';

const CLA_VIRTUAL_ADVISOR_COMPANY_ID = 56;

interface TitleProps {
  isMobile: boolean;
}

const Title: React.FC<TitleProps> = ({ isMobile }) => (
  <Typography variant="h1" fontSize={isMobile ? '40px' : '50px'}>
    Bienvenid@
  </Typography>
);

const DescriptionInfoText = () => (
  <Typography variant="body1">
    Soy tu Consejero Financiero Digital, acá podrás recibir consejos de educación financiera para optimizar tus finanzas
    personales. Si deseas, te podré dar recomendaciones prácticas que te ayudarán a comprender y evaluar temas
    financieros de tu interés, como ahorro, presupuestos, gestión de deudas y más.
  </Typography>
);

const Footer = ({ isMobile }: { isMobile: boolean }) => (
  <Box className={styles.Footer}>
    <img src={CajaLosAndesLogo} alt="Caja Los Andes Logo" width={isMobile ? '100px' : '140px'} />
    <img src={RelifLogo} alt="Relif Logo" width={isMobile ? '100px' : '140px'} style={{ marginBottom: '1rem' }} />
  </Box>
);

const PlayButton = () => (
  <Box
    className={styles.PlayButton}
    onClick={() => window.open('https://youtu.be/rHAfKlELGmM', '_blank')}
    sx={{ cursor: 'pointer' }}
  >
    <Box className={styles.Header}>
      <Typography variant="body1" color="white" fontWeight="bold">
        Video explicativo
      </Typography>
    </Box>
    <Box className={styles.Play}>
      <img src={PlayButtonIcon} alt="Play Button" />
    </Box>
  </Box>
);

const DisclaimerText = ({ mt, ml }: { mt: number; ml: number }) => (
  <Typography variant="body1" mt={mt} ml={ml}>
    Nuestro consejero financiero:
    <li>Nunca te pedirá ni hará uso de tu dinero</li>
    <li>Nunca te obligará a solicitar nada que tu no desees</li>
    <li>Jamás te ofrecerá dinero directamente</li>
    <li>Nunca podrá solicitar ni tramitar un crédito, una portabilidad o abrir una cuenta a tu nombre</li>
    <li>Solo tú decides qué hacer 😉</li>
  </Typography>
);

const CLAVirtualAdvisorDashboard = () => {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const token = useAccessToken();
  const tokenIsValid = checkIfTokenIsValid(token);
  const debtFlow = useDebtFlow();
  const [getUserInformation, { data: userInformation }] = useLazyGetUserInformationQuery();
  const [getLastCMFRequest, { isSuccess: isLastCMFRequestSuccess }] = useLazyGetLastCMFRequestQuery();

  const handleClick = async () => {
    setIsSignUpModalOpen(true);
  };

  // Fetch user information once the user is logged in
  useEffect(() => {
    if (tokenIsValid) {
      getUserInformation(null).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching user information');
      });
    }
  }, [tokenIsValid]);

  // Fetch CMF request once the user information is fetched
  useEffect(() => {
    if (userInformation && userInformation.rut) {
      getLastCMFRequest(null).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching CMF request');
      });
    }
    if (userInformation && !userInformation.rut) {
      navigate('/onboarding/caja-los-andes?redirectUrl=/chat?refetchFlag=1');
    }
  }, [userInformation]);

  // Redirect to the chat page if the user has a CMF response
  useEffect(() => {
    if (!isLastCMFRequestSuccess) return;
    if (debtFlow.CMFResponse) {
      navigate('/chat');
    } else {
      navigate('/onboarding/caja-los-andes?redirectUrl=/chat?refetchFlag=1');
    }
  }, [debtFlow.CMFResponse, isLastCMFRequestSuccess]);

  if (isMobile) {
    return (
      <Box className={styles.DashboardContainer}>
        <FinancialAdvisorAuthModal
          open={isSignUpModalOpen}
          onClose={() => setIsSignUpModalOpen(false)}
          companyId={CLA_VIRTUAL_ADVISOR_COMPANY_ID}
          defaultIsExistingUser={false}
        />
        <Box className={styles.Card} display="flex" flexDirection="column">
          <Box className={styles.DescriptionContainer}>
            <Box className={styles.Title}>
              <Title isMobile={isMobile} />
            </Box>
            <Box className={styles.DescriptionInfo}>
              <DescriptionInfoText />
              <ChatButton Icon={ArrowRightIcon} text="Comenzar" handleClick={() => handleClick()} />
              <Footer isMobile={isMobile} />
            </Box>
          </Box>
          <Box className={styles.Illustration} />
        </Box>
        <Box className={styles.Card}>
          <PlayButton />
        </Box>
        <DisclaimerText mt={2} ml={0} />
      </Box>
    );
  }
  return (
    <Box className={styles.DashboardContainer}>
      <FinancialAdvisorAuthModal
        open={isSignUpModalOpen}
        onClose={() => setIsSignUpModalOpen(false)}
        companyId={CLA_VIRTUAL_ADVISOR_COMPANY_ID}
        defaultIsExistingUser={false}
      />
      <Grid container>
        <Grid item xs={12}>
          <Box className={`${styles.Card} ${styles.MainCard}`}>
            <Grid container>
              <Grid item xs={4}>
                <Box className={styles.Illustration} />
              </Grid>
              <Grid item xs={8}>
                <Box className={styles.DescriptionContainer}>
                  <Box className={styles.Title}>
                    <Title isMobile={isMobile} />
                  </Box>
                  <Box className={styles.DescriptionInfo}>
                    <DescriptionInfoText />
                    <ChatButton Icon={ArrowRightIcon} text="Comenzar" handleClick={() => handleClick()} />
                    <Footer isMobile={isMobile} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4} pr={4}>
              <Box className={styles.Card}>
                <PlayButton />
              </Box>
            </Grid>
            <Grid item xs={8} className={styles.ButtonGrid} my={3}>
              <DisclaimerText ml={2} mt={2} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CLAVirtualAdvisorDashboard;
