import React from 'react';

import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

import { ChatIcon } from 'assets/icons';

const CustomFab = styled(Fab)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  position: 'fixed',
  bottom: '10px',
  right: '10px',
  height: '45px',
  width: '45px',
  '&:hover': {
    backgroundColor: theme.palette.info.main,
  },
  [theme.breakpoints.up('sm')]: {
    bottom: '20px',
    right: '30px',
    height: '58px',
    width: '58px',
  },
}));

const ChatButton = () => {
  return (
    <a href="/chat">
      <CustomFab>
        <ChatIcon sx={{ color: 'white', fontSize: { xs: '25px', xl: '30px' } }} />
      </CustomFab>
    </a>
  );
};

export default ChatButton;
