import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import { captureUserEvent } from 'utils/posthogUtils';

const FINANCIAL_INSTITUTION_LOGOS = [
  {
    imageUrl: '/landing_page/img/companies/banco-de-chile.webp',
    name: 'Banco de Chile',
    href: 'https://www.bancodechile.cl/',
  },
  {
    imageUrl: '/landing_page/img/companies/banco-internacional.webp',
    name: 'Banco Internacional',
    href: 'https://www.internacional.cl/',
  },
  {
    imageUrl: '/landing_page/img/companies/caja-los-andes.webp',
    name: 'Caja los Andes',
    href: 'https://www.cajalosandes.cl/',
  },
];

interface RefiRobotProps {
  handleOpenFinancialAdvisorAuthModal: (isExistingUser: boolean) => void;
}

const RefiRobot = ({ handleOpenFinancialAdvisorAuthModal }: RefiRobotProps) => {
  return (
    <Box>
      <Grid
        container
        justifyContent="center"
        spacing={{ xs: 0, lg: 8 }}
        pb={0}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item justifyContent="flex-end" display="flex" flexDirection="column" alignItems="center">
          <Box width={{ xs: '80%', sm: '40%', md: '70%', lg: '80%' }} height="100%">
            <img alt="" src="/landing_page/img/phone.png" style={{ width: '100%', height: '100%' }} />
          </Box>
        </Grid>
        <Grid item alignSelf="center" sx={{ pt: { xs: 2, md: 0 } }}>
          <Typography fontFamily="Roboto" fontWeight={700} fontSize={22} lineHeight="30px" textAlign="center">
            Mejora las condiciones de tus créditos gracias a nuestras instituciones amigas
          </Typography>
          <Box display="flex" flexDirection="row" gap={4} justifyContent="center" my={4}>
            {FINANCIAL_INSTITUTION_LOGOS.map((logo) => (
              <Box
                key={logo.name}
                onClick={() => window.open(logo.href, '_blank')}
                sx={{ cursor: 'pointer' }}
                borderRadius={5}
                overflow="hidden"
                height="100px"
              >
                <img src={logo.imageUrl} alt={logo.name} style={{ height: '100px' }} />
              </Box>
            ))}
          </Box>
          <Box display="flex" justifyContent="center" my={2}>
            <PrimaryBtn
              variant="outlined"
              sx={{
                color: 'black',
                backgroundColor: '#F9D00A',
                borderColor: '#F9D00A',
                '&:hover': {
                  backgroundColor: '#DCB705',
                  borderColor: '#DCB705',
                  color: 'black',
                },
                width: '200px',
              }}
              onClick={() => {
                captureUserEvent('CaughtAttention: First registration attempt');
                handleOpenFinancialAdvisorAuthModal(false);
              }}
            >
              Registrarse
            </PrimaryBtn>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RefiRobot;
