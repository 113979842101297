import React, { useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import SectionHeader from 'components/layout/SectionHeader';
import { useListDebtsQuery } from 'services/api/debt.api';
import { useGetFinancialStatusMutation } from 'services/api/financialStatus.api';
import { useGetBankOfferRequestsQuery } from 'services/api/user.api';

import BankOfferRequestCard from './components/BankOfferRequestCard';
import DebtCard from './components/DebtCard';
import TooltipDefinition from './components/TooltipDefinition';

const Circle = ({ color }: { color: string }) => {
  return <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: color }} />;
};

const DebtProfile = () => {
  const { data: debts } = useListDebtsQuery(null);

  const filteredDebts =
    debts
      ?.filter((debt) => typeof debt.source === 'string' && debt.source.toLowerCase() !== 'zeller')
      .map((debt) => ({
        ...debt,
        debtType: debt.debtType?.split(' ').pop() || debt.debtType,
      })) || [];

  const onDueDebts = filteredDebts.filter((debt) => Number(debt.monthsBehind) === 0) || [];
  const unpaidDebts = filteredDebts.filter((debt) => Number(debt.monthsBehind) > 0) || [];
  const [getFinancialStatus, { data: financialStatus }] = useGetFinancialStatusMutation();
  const { data: bankOfferRequests } = useGetBankOfferRequestsQuery(null);

  const badVR = financialStatus && (financialStatus.VRLevel === 'B' || financialStatus.VRLevel === 'C');
  const VRColor = badVR ? 'error.main' : 'success.main';
  const badRCR = financialStatus && (financialStatus.RCRLevel === 'B' || financialStatus.RCRLevel === 'C');
  const RCRColor = badRCR ? 'error.main' : 'success.main';

  useEffect(() => {
    getFinancialStatus(null).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(`Error al obtener el estado financiero: ${e}`);
    });
  }, []);

  return (
    <Box sx={{ height: { xs: '100vh' }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <SectionHeader text="Mi perfil financiero" button={null} />
      <Box sx={{ width: { xs: '100%', md: '60%' }, p: 4, overflowY: 'auto' }} flex={11}>
        {onDueDebts?.length > 0 && (
          <Box>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="h6" color="primary.main">
                  Créditos al día
                </Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="flex-end" alignItems="center">
                <Typography variant="h6" textAlign="right" color="text.primary" fontWeight={600} fontSize="16px">
                  Saldos
                </Typography>
              </Grid>
            </Grid>
            {onDueDebts.map((debt, index) => (
              <DebtCard debt={debt} key={debt.id} isLastRow={index === onDueDebts.length - 1} />
            ))}
          </Box>
        )}
        {unpaidDebts?.length > 0 && (
          <Box mt={3}>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="h6" color="primary.main">
                  Créditos atrasados
                </Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="flex-end" alignItems="center">
                <Typography variant="h6" textAlign="right" color="text.primary" fontWeight={600} fontSize="16px">
                  Saldo
                </Typography>
              </Grid>
            </Grid>
            {unpaidDebts.map((debt, index) => (
              <DebtCard debt={debt} key={debt.id} isLastRow={index === unpaidDebts.length - 1} />
            ))}
          </Box>
        )}
        {financialStatus && (
          <Box mt={3}>
            <Typography variant="h6" color="primary.main">
              Indicadores
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <TooltipDefinition
                  label="Carga financiera"
                  term="carga financiera"
                  definition="Porcentaje de los ingresos mensuales que se destina al pago de deudas"
                  align="left"
                />
                <Box display="flex" alignItems="center" gap={1}>
                  <Circle color={RCRColor} />
                  <Typography variant="body1" color="primary.main" fontSize="16px">
                    {Number(financialStatus.RCR || 0).toFixed(2)}%
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <TooltipDefinition
                  label="Veces Renta"
                  term="veces renta"
                  definition="Relación entre la deuda total y el ingreso mensual de una persona"
                  align="right"
                />
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  textAlign="right"
                  justifyContent="flex-end"
                  alignContent="center"
                >
                  <Circle color={VRColor} />
                  <Typography variant="body1" color="primary.main" fontSize="16px" textAlign="right">
                    {Number(financialStatus.VR || 0).toFixed(2)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        {bankOfferRequests && bankOfferRequests.length > 0 && (
          <Box mt={3}>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="h6" color="primary.main">
                  Solicitudes
                </Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="flex-end" alignItems="center">
                <Typography variant="h6" textAlign="right" color="text.primary" fontWeight={600} fontSize="16px">
                  Status
                </Typography>
              </Grid>
            </Grid>
            {bankOfferRequests.map((bankOfferRequest, index) => (
              <BankOfferRequestCard
                bankOfferRequest={bankOfferRequest}
                key={bankOfferRequest.id}
                isLastRow={index === bankOfferRequests.length - 1}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DebtProfile;
