import { getAuth, signInWithPopup, GoogleAuthProvider, User } from 'firebase/auth';

import { app } from '../fireBaseConfig';

// Adjust the import based on your file structure

const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });

const signInWithGoogle = async (): Promise<{ user: User | null; token: string | null }> => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const { user } = result;
    const idToken = await user.getIdToken();
    return { user, token: idToken };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error during Google Sign-In:', error);
    return { user: null, token: null };
  }
};

const signInWithFacebook = async (): Promise<{ user: User | null; token: string | null }> => {
  throw new Error('Facebook Sign-In is not implemented');
};

export default async (provider: 'google' | 'facebook'): Promise<{ user: User | null; token: string | null }> => {
  const signInFunction = provider === 'google' ? signInWithGoogle : signInWithFacebook;
  return signInFunction();
};
