import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Container, Typography, CircularProgress, Avatar } from '@mui/material';

import { findCMFInstitutionsLogo } from 'assets/data/institutionTypeList';
import ErrorImg from 'assets/images/404.webp';
import CelebrationIcon from 'assets/images/celebration_icon.svg';
import PrimaryBtn, { SecondaryBtn } from 'components/UI/Buttons';
import ChatWrapper from 'components/common/ChatWrapper';
import { useGetBankOffersQuery } from 'services/api/bankOffer.api';
import { captureUserEvent } from 'utils/posthogUtils';

const getBankDescription = (bank: string) => {
  switch (bank) {
    case 'Banco de Chile':
      return 'El Banco de Chile es una de las principales instituciones financieras del país, reconocida por su solidez y trayectoria.';
    case 'Banco Internacional':
      return 'Banco Internacional, parte del grupo CChC, es un banco chileno 100% digital, ideal para gestionar finanzas de forma simple y segura.';
    default:
      return '';
  }
};

const BankOffers: FC = () => {
  const { data: bankOffers, isLoading } = useGetBankOffersQuery(null);
  const navigate = useNavigate();
  if (isLoading) {
    return <CircularProgress />;
  }

  if (!bankOffers) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h2" mb={2}>
          Oops... La oferta que buscas no existe. Revisa la url o quizás la oferta expiró
        </Typography>
        <ChatWrapper>
          <SecondaryBtn>Contáctanos</SecondaryBtn>
        </ChatWrapper>
        <img src={ErrorImg} alt="Error" style={{ width: 'min(600px, 98%)' }} />
      </Container>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 4,
        px: 1,
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', md: '600px' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          overflowY: 'auto',
          borderRadius: 4,
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
          <img src={CelebrationIcon} alt="celebration icon" width="58px" height="58px" />
          <Typography
            variant="h1"
            sx={{
              fontSize: 18,
              width: '100%',
              mt: 3,
              fontWeight: 600,
              color: 'text.primary',
              textAlign: 'center',
            }}
          >
            Conseguimos {bankOffers?.length} {bankOffers?.length > 1 ? 'pre-ofertas' : 'pre-oferta'} para ti
          </Typography>
        </Box>
        <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
          {bankOffers?.map((bankOffer) => (
            <Box
              key={bankOffer.id}
              id="header"
              sx={{
                border: '1px solid',
                borderColor: '#9FC6EA',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                py: 2,
                px: 3,
                borderRadius: 4,
                mt: 8,
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#9FC6EA',
                  borderRadius: '20px',
                  width: '75%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '50px',
                  mt: '-50px',
                  px: 1,
                  mb: 2,
                }}
              >
                <Avatar src={findCMFInstitutionsLogo(bankOffer.bank)} sx={{ width: '35px', height: '35px' }} />
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: 18,
                    fontWeight: 400,
                    color: 'primary.main',
                    textAlign: 'left',
                    ml: 1,
                  }}
                >
                  {bankOffer.bank}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: 16,
                  width: '100%',
                  mt: 1,
                  fontWeight: 400,
                  color: 'text.primary',
                  textAlign: 'justify',
                }}
              >
                {getBankDescription(bankOffer.bank)}
              </Typography>
              <PrimaryBtn
                variant="contained"
                sx={{ width: '150px', mt: 2 }}
                onClick={() => {
                  captureUserEvent('BankOfferFlow: Clicked on a bank offer', {
                    bank: bankOffer.bank,
                    bankOfferUuid: bankOffer.uuid,
                  });
                  navigate(`/bank-offers/${bankOffer.uuid}`);
                }}
              >
                Ver detalles
              </PrimaryBtn>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default BankOffers;
