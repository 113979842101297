import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, Dialog } from '@mui/material';

import { PrimaryBtn } from 'components/UI/Buttons';
import useQuery from 'hooks/useQuery';
import trpc from 'trpc';
import getBankOfferInstructions from 'utils/getBankOfferInstructions';

import OfferContainer from '../OfferContainer';
import RefinanceOffer from '../RefinanceOffer';

const BankOffer = () => {
  const { uuid } = useParams();
  const query = useQuery();
  const redirectUrl = query.get('redirectUrl');
  const navigate = useNavigate();
  if (!uuid) {
    return null;
  }
  const { data: bankOffer } = trpc.bankOffers.list.useQuery({ uuid });
  const setUserReaction = trpc.bankOffers.userReaction.useMutation();
  const [selectedTerm, setSelectedTerm] = useState<number | null>(null);
  const [openSelectedOptionDialog, setOpenSelectedOptionDialog] = useState(false);

  const acceptedTitle = `¡Felicidades! Según nuestros registros, aceptaste la oferta para ${
    bankOffer?.cashPaymentAmount ? 'saldar' : 'renegociar '
  } tu deuda con ${bankOffer?.bank}${bankOffer?.description ? '*' : ''}`;

  const instructions = getBankOfferInstructions(bankOffer || null);

  const setInterested = (id: number, term: number): void => {
    if (!bankOffer?.uuid) {
      return;
    }
    setSelectedTerm(term);
    setOpenSelectedOptionDialog(true);
    setUserReaction.mutate({
      uuid: bankOffer.uuid,
      id,
      userReaction: `interested in ${term} months`,
    });
  };

  if (!bankOffer) {
    return null;
  }

  if (bankOffer?.isAccepted) {
    return (
      <OfferContainer>
        <Typography
          variant="h2"
          sx={{
            fontSize: { lg: '25px', xs: '25px' },
            width: '100%',
            mb: '15px',
            fontFamily: 'Roboto',
            fontWeight: 800,
            color: 'white',
            textAlign: 'center',
          }}
        >
          {acceptedTitle}
        </Typography>
        <Typography color="white" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>
          ¿Tienes dudas? ¿Crees que fue un error? <br />
          <a
            href="/book-meeting"
            style={{ color: 'white', textDecoration: 'underline' }}
            target="_blank"
            rel="noreferrer"
          >
            Agenda aquí una asesoría con el equipo de Relif
          </a>
        </Typography>
      </OfferContainer>
    );
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" p={1}>
        <PrimaryBtn onClick={() => navigate('/bank-offers')}>Volver</PrimaryBtn>
        <PrimaryBtn onClick={() => navigate(redirectUrl || '/debt')}>Salir</PrimaryBtn>
      </Box>

      <Box sx={{ width: { xs: '100%', md: '80%', lg: '50%', xl: '40%' } }} p={1}>
        <Dialog
          open={openSelectedOptionDialog}
          onClose={() => setOpenSelectedOptionDialog(false)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <Typography variant="body1" m={2}>
            {instructions}
          </Typography>
        </Dialog>
        <RefinanceOffer bankOffer={bankOffer} setInterested={setInterested} selectedTerm={selectedTerm} />
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" p={1}>
          Si no estás interesado en la oferta, puedes{' '}
          <span style={{ padding: '0 5px' }}>
            <a href={redirectUrl || '/debt'}>seguir adelante</a>
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default BankOffer;
