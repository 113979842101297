import React from 'react';
import { useNavigate } from 'react-router-dom';

import ChatIcon from '@mui/icons-material/Chat';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.info.main,
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.info.main}`,
  borderRadius: '20px',
  height: '43px',
  minWidth: '125px',
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 600,
  textTransform: 'uppercase',
  transition: 'all 0.15s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.info.light,
    borderColor: theme.palette.info.main,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: '201px',
  },
}));

interface Props {
  redirectUrl?: string;
}

const ChatRedirectButton: React.FC<Props> = ({ redirectUrl }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (redirectUrl) {
      navigate(redirectUrl);
    }
  };

  return (
    <StyledButton onClick={handleClick} startIcon={<ChatIcon />}>
      Seguir al chat
    </StyledButton>
  );
};

ChatRedirectButton.defaultProps = {
  redirectUrl: undefined,
};

export default ChatRedirectButton;
