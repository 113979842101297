import type {
  DebtsReport,
  DebtProfile as UserDebtProfile,
  DebtProfileToUpdate as UserDebtProfileToUpdate,
  BankOfferRequestAttributes,
} from '@relif/backend';
import type { FloidCMFResponse } from '@relif/floid-helpers';

import { HubspotMeeting } from '../types/hubspotMeetings';
import {
  ChangeInformationBody,
  SignInBody,
  SignInResponse,
  SignUpBody,
  FinancialAdvisorSignUpBody,
  User,
  UserFinancialStatus,
  UserRefinanceStatus,
  WaitListBody,
  ExternalAuthProviderSignInBody,
  FinancialAdvisorNoCredentialsSignUpBody,
} from '../types/user';
import baseApi from './baseApi';

const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    signUpUser: build.mutation({
      query: (body: SignUpBody) => ({
        url: '/auth/signup',
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response,
    }),
    financialAdvisorSignUpUser: build.mutation({
      query: (body: FinancialAdvisorSignUpBody) => ({
        url: '/auth/financial-advisor/signup',
        method: 'POST',
        body,
      }),
      transformResponse: (response: SignInResponse) => response,
    }),
    externalAuthProviderSignIn: build.mutation({
      query: (body: ExternalAuthProviderSignInBody) => ({
        url: '/auth/financial-advisor/external-auth-provider-signin',
        method: 'POST',
        body,
      }),
      transformResponse: (response: SignInResponse) => response,
    }),
    financialAdvisorNoCredentialsSignUpUser: build.mutation({
      query: (body: FinancialAdvisorNoCredentialsSignUpBody) => ({
        url: '/auth/financial-advisor/no-credentials-signup',
        method: 'POST',
        body,
      }),
      transformResponse: (response: SignInResponse) => response,
    }),
    signInUser: build.mutation({
      query: (body: SignInBody) => ({
        url: '/auth',
        method: 'POST',
        body,
      }),
      transformResponse: (response: SignInResponse) => response,
    }),
    addToWaitList: build.mutation({
      query: (body: WaitListBody) => ({
        url: '/auth/waitList',
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response,
    }),
    passwordRecovery: build.mutation({
      query: (props: { email?: string; rut?: string }) => ({
        url: '/auth/password/recovery',
        method: 'POST',
        body: props,
      }),
      transformResponse: (response: { message: string }) => response,
    }),
    passwordReset: build.mutation({
      query: (body: { password: string; token: string; email: string }) => ({
        url: '/auth/password/reset',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { message: string }) => response,
    }),
    changePassword: build.mutation({
      query: (body: { currentPassword: string; newPassword: string }) => ({
        url: '/users/password',
        method: 'PUT',
        body,
      }),
      transformResponse: (response) => response,
    }),
    changePasswordUniqueFlow: build.mutation({
      query: (body: { newPassword: string }) => ({
        url: '/users/password/unique-flow',
        method: 'PUT',
        body,
      }),
      transformResponse: (response) => response,
    }),
    changeInformation: build.mutation({
      query: (body: ChangeInformationBody) => ({
        url: '/users/information',
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: { message: string }): { message: string } => response,
    }),
    getUser: build.query({
      query: () => ({
        url: '/users/user',
        method: 'GET',
      }),
      transformResponse: (response: User): User => response,
    }),
    getActionPlan: build.query({
      query: () => ({
        url: '/users/actionPlan',
        method: 'GET',
      }),
      transformResponse: (response: { actionPlan: string }) => response,
    }),
    getMeetings: build.query({
      query: () => ({
        url: '/users/meetings',
        method: 'GET',
      }),
      transformResponse: (response: { meetings: HubspotMeeting[] }) => response,
    }),
    changeMeetingHost: build.mutation({
      query: (body: { meetingAdminId: number }) => ({
        url: '/users/meetingAdminId',
        method: 'PUT',
        body,
      }),
      transformResponse: (response) => response,
    }),
    editFinancialInfo: build.mutation({
      query: (body: UserFinancialStatus) => ({
        url: '/users/edit/financial',
        method: 'PUT',
        body,
      }),
      transformResponse: (response: User) => response,
    }),
    editInformationWithClaveUnica: build.mutation({
      query: (body: { claveUnica: string }) => ({
        url: '/users/clave_unica_v2',
        method: 'POST',
        body,
      }),
      transformResponse: (response: FloidCMFResponse) => response,
    }),
    informationAdditional: build.mutation({
      query: (body: { email: string; rut: string; isAddEmail: boolean; phone: string }) => ({
        url: '/users/information/additional',
        method: 'PATCH',
        body,
      }),
      transformResponse: (response) => response,
    }),
    updateCMFWithClaveUnica: build.mutation({
      query: (body: { claveUnica: string }) => ({
        url: '/users/clave_unica/cmf_v2',
        method: 'POST',
        body,
      }),
      transformResponse: (response: FloidCMFResponse) => response,
    }),
    getDebtsReport: build.mutation({
      query: () => ({
        url: '/users/debts_report',
        method: 'GET',
      }),
      transformResponse: (response: DebtsReport) => response,
    }),
    getRefinanceStatus: build.query({
      query: () => ({
        url: '/users/refinance_status',
        method: 'GET',
      }),
      transformResponse: (response: UserRefinanceStatus | null) => response,
    }),
    getUserDebtProfile: build.query({
      query: () => ({
        url: '/users/debt_profile',
        method: 'GET',
      }),
      transformResponse: (response: UserDebtProfile) => response,
    }),
    updateUserDebtProfile: build.mutation({
      query: (body: UserDebtProfileToUpdate) => ({
        url: '/users/debt_profile',
        method: 'PUT',
        body,
      }),
      transformResponse: (response: UserDebtProfileToUpdate) => response,
    }),
    getUserInformation: build.query({
      query: () => ({
        url: '/users/information',
        method: 'GET',
      }),
      transformResponse: (response: SignInResponse): SignInResponse => response,
    }),
    getBankOfferRequests: build.query({
      query: () => ({
        url: '/users/bankOfferRequests',
        method: 'GET',
      }),
      transformResponse: (response: BankOfferRequestAttributes[]) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignUpUserMutation,
  useFinancialAdvisorSignUpUserMutation,
  useFinancialAdvisorNoCredentialsSignUpUserMutation,
  useSignInUserMutation,
  useAddToWaitListMutation,
  usePasswordRecoveryMutation,
  usePasswordResetMutation,
  useChangePasswordMutation,
  useChangeInformationMutation,
  useGetUserQuery,
  useGetActionPlanQuery,
  useGetMeetingsQuery,
  useChangeMeetingHostMutation,
  useEditFinancialInfoMutation,
  useInformationAdditionalMutation,
  useGetDebtsReportMutation,
  useUpdateCMFWithClaveUnicaMutation,
  useEditInformationWithClaveUnicaMutation,
  useGetRefinanceStatusQuery,
  useGetUserDebtProfileQuery,
  useUpdateUserDebtProfileMutation,
  useChangePasswordUniqueFlowMutation,
  useGetUserInformationQuery,
  useLazyGetUserInformationQuery,
  useLazyGetUserQuery,
  useGetBankOfferRequestsQuery,
  useExternalAuthProviderSignInMutation,
} = userApi;

export default userApi;
