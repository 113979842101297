import React from 'react';

import { Box, CircularProgress, Tooltip } from '@mui/material';

interface LoadingTooltipProps {
  children: React.ReactElement;
  isLoading: boolean;
  title: string;
}

const LoadingTooltip: React.FC<LoadingTooltipProps> = ({ children, isLoading, title }) => {
  if (!isLoading) {
    return children;
  }

  return (
    <Tooltip
      open={isLoading}
      title={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CircularProgress size={16} color="inherit" />
          <span>{title}</span>
        </Box>
      }
      arrow
      placement="top-start"
    >
      {children}
    </Tooltip>
  );
};

export default LoadingTooltip;
