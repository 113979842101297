import React from 'react';

import { Box, Button } from '@mui/material';

import type { BankOffer } from '@relif/backend';
import TricotInstructionsImage from 'assets/images/tricot_instructions.png';
import priceFormat from 'services/format/priceFormat';

const getBankOfferInstructions = (bankOffer: BankOffer | null) => {
  if (!bankOffer) {
    return null;
  }
  if (bankOffer.bank === 'Tricot') {
    return (
      <Box>
        <h3>¿Cómo pagar tu oferta en Tricot?</h3>
        <ol>
          <li>
            Ingresa al portal de pago de tricot haciendo click aquí{' '}
            <a
              href="https://www.tricard.cl/paga-tu-cuenta/"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'blue', textDecoration: 'underline' }}
            >
              www.tricard.cl/paga-tu-cuenta/
            </a>
          </li>
          <li>
            Ingresa tu RUT y selecciona la opción “Realizar pago”
            <Box sx={{ display: 'flex', justifyContent: 'center' }} my={1}>
              <Button
                sx={{
                  backgroundColor: '#f30000',
                  color: 'white',
                  border: 'none',
                  display: 'block',
                  borderRadius: '3px',
                  width: '100%',
                  marginBottom: '5px',
                  fontSize: '1.15em',
                  padding: '5px',
                  maxWidth: '300px',
                  textTransform: 'none',
                  fontWeight: 500,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#c30000',
                  },
                }}
                onClick={() => {
                  window.open('https://www.tricard.cl/paga-tu-cuenta/', '_blank');
                }}
              >
                <span>Realizar Pago</span>
              </Button>
            </Box>
          </li>
          <li>
            Selecciona la opción “Otro monto” e ingresa el monto de tu oferta{' '}
            <b>({priceFormat.format(bankOffer.cashPaymentAmount || 0)})</b>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} my={1}>
              <img
                src={TricotInstructionsImage}
                alt="Tricot Instructions"
                style={{ maxWidth: '300px', boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)' }}
              />
            </Box>
          </li>
          <li>Selecciona tu medio de pago y completa la transacción</li>
          <li>Nosotros nos encargaremos de comunicarnos con Tricot para que marquen tu deuda como pagada</li>
        </ol>
      </Box>
    );
  }
  return bankOffer.instructions;
};

export default getBankOfferInstructions;
