import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AlertDialog from 'components/UI/AlertDialog';
import PrimaryBtn from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import SuccessDialog from 'components/UI/SuccessDialog';
import { useCreateProspectMutation } from 'services/api/prospects.api';
import validateEmail from 'utils/validations/validateEmail';

const Title = () => (
  <>
    <Typography fontFamily="Roboto" fontWeight={700} fontSize="22px" lineHeight="30px" pb={{ xs: 2, sm: 4 }}>
      Contáctanos
    </Typography>
    <Typography fontFamily="Jost" fontWeight={400} fontSize="22px" lineHeight="25px" color="#2C5889">
      Y lleva soluciones concretas a la salud financiera de tus personas.
    </Typography>
  </>
);

const ContactUsContent = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('569');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [prospectSuccess, setProspectSuccess] = useState(false);
  const [prospectError, setProspectError] = useState(false);

  const [createProspect, { isSuccess, isLoading, isError }] = useCreateProspectMutation();

  const validatePhone = (value: string) => value.length === 11;

  useEffect(() => {
    if (isSuccess) {
      setProspectSuccess(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setProspectError(true);
    }
  }, [isError]);

  return (
    <>
      <SuccessDialog
        openDialog={prospectSuccess}
        setOpenDialog={setProspectSuccess}
        header="Gracias por contactarnos!"
        msg="Nos pondremos en contacto contigo a la brevedad"
      />
      <AlertDialog
        openDialog={prospectError}
        setOpenDialog={setProspectError}
        header="Hubo un error"
        msg="Intenta nuevamente o escríbenos directamente a contacto@relif.cl"
        cancelMsg="Cerrar"
      />
      <Grid item xs={12} sm={6} alignSelf="center">
        <Box display={{ xs: 'none', sm: 'block' }}>
          <Title />
          <Box display="flex" justifyContent="center" py={4}>
            <svg
              width="100%"
              preserveAspectRatio="none"
              height="2"
              viewBox="0 0 180 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 1.00003L180 1.00005" stroke="#15183A" strokeWidth="2" />
            </svg>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box display={{ xs: 'block', sm: 'none' }} textAlign="center" pb={2}>
          <Title />
        </Box>
        <PaperContainer fullWidth elevation={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                value={firstName}
                required
                fullWidth
                label="Nombre"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="lastName"
                value={lastName}
                required
                fullWidth
                label="Apellido"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                value={email}
                required
                autoComplete="email"
                type="email"
                label="Email"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                error={email.length !== 0 && !validateEmail(email)}
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneInput
                country="cl"
                placeholder="Teléfono"
                value={phone}
                onChange={(value) => setPhone(value)}
                inputStyle={{
                  width: '100%',
                  height: '55px',
                  font: 'inherit',
                  paddingLeft: '48px',
                  borderRadius: '3px',
                  color: 'black',
                }}
                isValid={() => {
                  if (phone.length === 3 || validatePhone(phone)) {
                    return true;
                  }
                  return 'Largo de número incorrecto';
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="companyName"
                value={companyName}
                required
                fullWidth
                label="Empresa"
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <PrimaryBtn
                variant="outlined"
                fullWidth
                sx={{
                  color: 'black',
                  backgroundColor: '#F9D00A',
                  borderColor: '#F9D00A',
                  '&:hover': {
                    backgroundColor: '#DCB705',
                    borderColor: '#DCB705',
                    color: 'black',
                  },
                }}
                onClick={() => {
                  if (!isLoading && validateEmail(email) && validatePhone(phone)) {
                    createProspect({
                      email,
                      phone,
                      reason: `Contacto del landing: ${firstName} ${lastName} de ${companyName}`,
                    }).catch((e) => console.error(e)); // eslint-disable-line no-console
                  }
                }}
                disabled={isLoading || !validateEmail(email) || !validatePhone(phone)}
              >
                Contactar
              </PrimaryBtn>
            </Grid>
          </Grid>
        </PaperContainer>
      </Grid>
    </>
  );
};
const ContactUs = () => {
  // I had to do this because Grid does not support the breakpoint parameters in the direction prop, see: https://mui.com/material-ui/react-grid/#limitations
  return (
    <>
      <Grid
        container
        justifyContent="center"
        pt={{ xs: 20, sm: 24 }}
        pb={8}
        spacing={8}
        px={{ xs: 2, sm: 8 }}
        display={{ xs: 'none', sm: 'flex' }}
      >
        <ContactUsContent />
      </Grid>
      <Grid
        container
        justifyContent="center"
        pt={{ xs: 20, sm: 24 }}
        pb={8}
        spacing={8}
        px={{ xs: 2, sm: 8 }}
        direction="column-reverse"
        display={{ xs: 'flex', sm: 'none' }}
      >
        <ContactUsContent />
      </Grid>
    </>
  );
};

export default ContactUs;
