import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Grid } from '@mui/material';

import DashboardIllustration from 'assets/images/buk/dashboard_illustration.svg';
import DashboardIllustrationMobile from 'assets/images/buk/dashboard_illustration_mobile.svg';
import PlayButton from 'assets/images/buk/play_button.svg';
import { checkIfTokenIsValid } from 'authStorage';
import useAccessToken from 'hooks/useAccessToken';
import useDebtFlow from 'hooks/useDebtFlow';
import useIsMobile from 'hooks/useIsMobile';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { useLazyGetLastCMFRequestQuery } from 'services/api/floid.api';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';
import { captureUserEvent } from 'utils/posthogUtils';

import styles from './BukDashboard.module.scss';
import ChatButton from './components/ChatButton';
import RequestsHistory from './components/RequestsHistory';

const Description = ({ redirectUrl }: { redirectUrl: string }) => (
  <Box className={styles.DescriptionContainer}>
    <Box className={styles.Title}>
      <h1>¡Hola!</h1>
    </Box>
    <h2>Bienvenido a la plataforma de salud financiera de Buk en alianza con Relif</h2>
    <p>
      Acá podrás encontrar asesoría personalizada y soluciones para mejorar tu endeudamiento, ahorro, orden financiero y
      mucho más.
    </p>
    <Box className={styles.ButtonWrapper}>
      <ChatButton text="Comenzar" route={redirectUrl} />
    </Box>
  </Box>
);

const VideoButton = () => (
  <Box className={styles.Card}>
    <Box
      className={styles.PlayButton}
      onClick={() => {
        // navigate to video
        window.open('https://www.youtube.com/watch?v=JW2UxQK955Q', '_blank');
        captureUserEvent('Clicked on video button');
      }}
    >
      <Box className={styles.PlayButtonBackground} />
      <p>Ver video explicativo</p>
      <img src={PlayButton} alt="Play Button" />
    </Box>
  </Box>
);

const BukDashboard = () => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const [getLastCMFRequest] = useLazyGetLastCMFRequestQuery();
  const [getUserInformation, { data: userInformation }] = useLazyGetUserInformationQuery();
  const debtFlow = useDebtFlow();
  const token = useAccessToken();
  const tokenIsValid = checkIfTokenIsValid(token);
  // get accessToken from query params
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get('accessToken');

  const redirectUrl = debtFlow.CMFResponse ? '/chat' : '/onboarding/buk?redirectUrl=/chat?refetchFlag=1';

  useEffect(() => {
    if (accessToken) {
      dispatch(setAccessToken({ accessToken }));
    }
  }, [accessToken]);

  // get user information once the user is logged in
  useEffect(() => {
    if (tokenIsValid) {
      getUserInformation(null).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    }
  }, [tokenIsValid]);

  // get CMF request once the user information is fetched only if the user has a rut
  useEffect(() => {
    if (userInformation && userInformation.rut) {
      getLastCMFRequest(null).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    }
  }, [userInformation]);

  if (isMobile) {
    return (
      <Box className={styles.DashboardContainer}>
        <Box className={styles.Card} display="flex" flexDirection="column">
          <Description redirectUrl={redirectUrl} />
          <Box className={styles.Illustration}>
            <img src={DashboardIllustrationMobile} alt="Dashboard Illustration" />
          </Box>
        </Box>
        <RequestsHistory />
        <VideoButton />
      </Box>
    );
  }
  return (
    <Box className={styles.DashboardContainer}>
      <Grid container>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <Box className={`${styles.Card} ${styles.MainCard}`}>
                <Grid container>
                  <Grid item xs={4}>
                    <Box className={styles.Illustration}>
                      <img src={DashboardIllustration} alt="Dashboard Illustration" />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Description redirectUrl={redirectUrl} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} pr={4}>
                  <VideoButton />
                </Grid>
                <Grid item xs={8} pr={4}>
                  <RequestsHistory />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BukDashboard;
