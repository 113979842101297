import React, { FC, useState, useCallback, useEffect, Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Save from '@mui/icons-material/Save';
import { Box, Stack } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button } from '@dscla/ds-button/react';
import { DeleteIcon } from 'assets/icons';
import AlertDialog from 'components/UI/AlertDialog';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import { ObjectiveTable } from 'pages/Budget/components/FormTable';
import { useCreateBudgetMutation, useEditBudgetMutation, useDeleteBudgetMutation } from 'services/api/budgets.api';
import { useGetSingleConstantQuery } from 'services/api/constants.api';
import { useListDebtsQuery } from 'services/api/debt.api';
import { useGetGoalsMutation } from 'services/api/goals.api';
import priceFormat from 'services/format/priceFormat';
import type { BudgetRowDraft } from 'services/types/budget';
import { Debt } from 'services/types/debt';
import { Goal } from 'services/types/goals';
import { captureUserEvent } from 'utils/posthogUtils';

import styles from '../EditOrCreateBudget.module.scss';
import {
  HOME_SUBCATEGORIES,
  PERSONAL_SUBCATEGORIES,
  CHILD_SUBCATEGORIES,
  ENTERTAINMENT_SUBCATEGORIES,
  FINANCE_SUBCATEGORIES,
} from '../constants';
import BudgetSummary from './BudgetSummary';
import { IncomeTable, ExpenseTable } from './FormTable';
import SectionAccordion from './SectionAccordion';

const implicitTermTypes = ['tarjeta de crédito', 'linea de crédito'];
interface BudgetFormInterface {
  inicialIncomeRows: BudgetRowDraft[];
  inicialHomeRows: BudgetRowDraft[];
  inicialFamilyRows: BudgetRowDraft[];
  inicialChildRows: BudgetRowDraft[];
  inicialEntertainmentRows: BudgetRowDraft[];
  inicialFinanceRows: BudgetRowDraft[];
  inicialGoalRows: BudgetRowDraft[];
  isEdit: boolean;
  budgetID: null | number;
  name: string;
}

const BudgetForm: FC<BudgetFormInterface> = ({
  inicialChildRows,
  inicialHomeRows,
  inicialIncomeRows,
  inicialEntertainmentRows,
  inicialFamilyRows,
  inicialFinanceRows,
  inicialGoalRows,
  isEdit,
  budgetID,
  name,
}) => {
  // const [name, setName] = useState<string>('Presupuesto principal');
  const [incomeRows, setIncomeRows] = useState<BudgetRowDraft[]>(inicialIncomeRows);
  const [homeRows, setHomeRows] = useState<BudgetRowDraft[]>(inicialHomeRows);
  const [familyRows, setFamilyRows] = useState<BudgetRowDraft[]>(inicialFamilyRows);
  const [childRows, setChildRows] = useState<BudgetRowDraft[]>(inicialChildRows);
  const [entertainmentRows, setEntertainmentRows] = useState<BudgetRowDraft[]>(inicialEntertainmentRows);
  const [financeRows, setFinanceRows] = useState<BudgetRowDraft[]>(inicialFinanceRows);
  const [goalRows, setGoalRows] = useState<BudgetRowDraft[]>(inicialGoalRows);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [createBudget, { data: budgetData, isLoading }] = useCreateBudgetMutation();
  const [editBudget, { data: editBudgetData, isLoading: editLoading }] = useEditBudgetMutation();
  const [deleteBudget, { data: deleteBudgetData, isLoading: deleteLoading }] = useDeleteBudgetMutation();
  const navigate = useNavigate();
  const [getGoals, { data: goalsData }] = useGetGoalsMutation();
  const { data: debtsData } = useListDebtsQuery(null);
  const { data: UF_TO_CLP } = useGetSingleConstantQuery('UF_TO_CLP');

  const calculateGoalAmount = (oldGoal: Goal) => {
    const goals = inicialGoalRows.filter((goal) => goal.goalId === oldGoal.id);
    if (isEdit && goals.length > 0) {
      return goals[0].amount;
    }
    const amount = oldGoal.term ? Math.round(Number(oldGoal.amount) / Number(oldGoal.term)) : Number(oldGoal.amount);
    return amount;
  };

  const generateGoalRows = () => {
    const rows: BudgetRowDraft[] = [];
    goalsData?.forEach((goal) => {
      rows.push({
        name: goal.name,
        category: 'Objetivos',
        isExpense: true,
        amount: calculateGoalAmount(goal),
        goalId: goal.id,
        subcategory: '',
      });
    });
    setGoalRows(rows);
  };

  const getDebtAmount = (debt: Debt) => {
    const debtAmount =
      (implicitTermTypes.includes(debt.debtType?.toLowerCase() || '') ? debt.amount : debt.monthlyPayment) || 0;
    const amountInCLP = debt.currency === 'UF' && UF_TO_CLP ? debtAmount * (UF_TO_CLP.numberValue || 0) : debtAmount;
    return Math.round(amountInCLP);
  };

  const generateDebtRows = () => {
    const rows: BudgetRowDraft[] = [];
    debtsData?.forEach((debt) => {
      const debtAmount = getDebtAmount(debt);
      if (
        !financeRows.find(
          (row) =>
            row.name === `Cuota ${debt.debtType || ''} - ${debt.institutionName || ''}` && row.amount === debtAmount
        )
      ) {
        rows.push({
          name: `Cuota ${debt.debtType || ''} - ${debt.institutionName || ''}`,
          category: 'Financiero',
          isExpense: true,
          amount: debtAmount,
          debtId: debt.id,
          subcategory: 'Créditos',
        });
      }
    });
    setFinanceRows([...financeRows, ...rows]);
  };
  useEffect(() => {
    const getGoalsData = async () => {
      await getGoals(null);
    };
    // eslint-disable-next-line no-void
    void getGoalsData();
  }, []);

  useEffect(() => {
    if (goalsData) {
      generateGoalRows();
    }
  }, [goalsData]);

  useEffect(() => {
    if (budgetData || editBudgetData || deleteBudgetData) {
      navigate('/budget');
      navigate(0);
    }
  }, [budgetData, editBudgetData, deleteBudgetData]);

  useEffect(() => {
    if (debtsData && UF_TO_CLP) {
      generateDebtRows();
    }
  }, [debtsData, UF_TO_CLP]);

  const totalIncome = incomeRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);

  const totalHome = homeRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);

  const totalFamily = familyRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);

  const totalChild = childRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);

  const totalEntertain = entertainmentRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);

  const totalFinance = financeRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);

  const countRowsCompleted = (rows: BudgetRowDraft[]): string => {
    const array: BudgetRowDraft[] = rows.filter((row) => {
      return Number(row.amount) > 0;
    });
    const total = rows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);
    return `${priceFormat.format(total)} (${array.length})`;
  };

  const totalGoal =
    goalRows.length > 0 ? goalRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next) : 0;

  const deleteWarning = '¿Estas seguro que quieres eliminar este presupuesto?';
  const deleteWarningMsg = 'Esta acción no se puede deshacer.';

  const generateSetRowAmount = useCallback(
    (amount: number | string, index: number, rows: BudgetRowDraft[], setRows: Dispatch<BudgetRowDraft[]>) => {
      const newRow: BudgetRowDraft = {
        ...rows[index],
        amount: amount === '' ? '' : Number(amount),
      };
      setRows([...rows.slice(0, index), newRow, ...rows.slice(index + 1)]);
    },
    []
  );

  const setRowAttribute = useCallback(
    (
      budgetRowName: string,
      index: number,
      attribute: 'name' | 'category' | 'subcategory',
      rows: BudgetRowDraft[],
      setRows: Dispatch<BudgetRowDraft[]>
    ) => {
      const budgetRow: BudgetRowDraft = {
        ...rows[index],
        [attribute]: budgetRowName,
      };
      setRows([...rows.slice(0, index), budgetRow, ...rows.slice(index + 1)]);
    },
    []
  );

  const addRow = useCallback(
    (rows: BudgetRowDraft[], setRows: Dispatch<BudgetRowDraft[]>, isExpense: boolean, category: string) => {
      const newRow: BudgetRowDraft = {
        isExpense,
        category: rows[0] ? rows[0].category : category,
        subcategory: '',
        name: '',
        amount: '',
      };
      setRows([...rows, newRow]);
    },
    []
  );

  const removeRow = useCallback((index: number, rows: BudgetRowDraft[], setRows: Dispatch<BudgetRowDraft[]>) => {
    setRows([...rows.slice(0, index), ...rows.slice(index + 1)]);
  }, []);

  const generateAllRows = () => {
    return [
      ...incomeRows.filter((row) => Number(row.amount) > 0),
      ...homeRows.filter((row) => Number(row.amount) > 0),
      ...familyRows.filter((row) => Number(row.amount) > 0),
      ...financeRows.filter((row) => Number(row.amount) > 0),
      ...entertainmentRows.filter((row) => Number(row.amount) > 0),
      ...goalRows.filter((row) => Number(row.amount) > 0),
      ...childRows.filter((row) => Number(row.amount) > 0),
    ];
  };

  const handleSubmit = async () => {
    const allRows = generateAllRows();
    if (isEdit) {
      await editBudget({
        body: {
          name,
          rows: allRows,
        },
        id: Number(budgetID),
      });
    } else {
      await createBudget({
        name,
        rows: allRows,
      });
    }
  };

  const handleSaveButton = async () => {
    await handleSubmit();
    captureUserEvent('ValuableAction: Budget saved', {
      isEdit,
    });
  };

  const handleDelete = async () => {
    if (budgetID) {
      await deleteBudget(budgetID);
    }
  };

  const handleDeleteButton = () => {
    setOpenDeleteDialog(true);
  };

  return (
    <div className={styles.MainContainer}>
      <LoadingOverlay visible={isLoading || editLoading || deleteLoading} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            width: {
              lg: '100%',
              xl: '60%',
              borderRadius: '20px',
            },
          }}
        >
          <SectionAccordion
            IncomeForm={
              <IncomeTable
                budgetRows={incomeRows}
                setBudgetRows={setIncomeRows}
                setBudgetRowAmount={generateSetRowAmount}
                setBudgetRowStringAttribute={setRowAttribute}
                removeRow={removeRow}
                addRow={addRow}
              />
            }
            incomeBadge={countRowsCompleted(incomeRows)}
            HomeForm={
              <ExpenseTable
                budgetRows={homeRows}
                setBudgetRows={setHomeRows}
                setBudgetRowAmount={generateSetRowAmount}
                setBudgetRowStringAttribute={setRowAttribute}
                removeRow={removeRow}
                addRow={addRow}
                formName="Hogar"
                subcategories={HOME_SUBCATEGORIES}
              />
            }
            homeBadge={countRowsCompleted(homeRows)}
            FamilyForm={
              <ExpenseTable
                budgetRows={familyRows}
                setBudgetRows={setFamilyRows}
                setBudgetRowAmount={generateSetRowAmount}
                setBudgetRowStringAttribute={setRowAttribute}
                removeRow={removeRow}
                addRow={addRow}
                formName="Personal"
                subcategories={PERSONAL_SUBCATEGORIES}
              />
            }
            familyBadge={countRowsCompleted(familyRows)}
            ChildForm={
              <ExpenseTable
                budgetRows={childRows}
                setBudgetRows={setChildRows}
                setBudgetRowAmount={generateSetRowAmount}
                setBudgetRowStringAttribute={setRowAttribute}
                removeRow={removeRow}
                addRow={addRow}
                formName="Hijos"
                subcategories={CHILD_SUBCATEGORIES}
              />
            }
            childBadge={countRowsCompleted(childRows)}
            EntertainmentForm={
              <ExpenseTable
                budgetRows={entertainmentRows}
                setBudgetRows={setEntertainmentRows}
                setBudgetRowAmount={generateSetRowAmount}
                setBudgetRowStringAttribute={setRowAttribute}
                removeRow={removeRow}
                addRow={addRow}
                formName="Entretención"
                subcategories={ENTERTAINMENT_SUBCATEGORIES}
              />
            }
            entertainmentBadge={countRowsCompleted(entertainmentRows)}
            FinanceForm={
              <ExpenseTable
                budgetRows={financeRows}
                setBudgetRows={setFinanceRows}
                setBudgetRowAmount={generateSetRowAmount}
                setBudgetRowStringAttribute={setRowAttribute}
                removeRow={removeRow}
                addRow={addRow}
                formName="Financiero"
                subcategories={FINANCE_SUBCATEGORIES}
                debtsData={debtsData}
              />
            }
            financeBadge={countRowsCompleted(financeRows)}
            ObjectiveForm={<ObjectiveTable budgetRows={goalRows} />}
            objectiveBadge={countRowsCompleted(goalRows)}
          />
          <Box display={{ xs: 'none', sm: 'flex' }} width="100%" justifyContent="space-between">
            <Button onClick={() => navigate('/budget')} variant="texto">
              <span slot="start">
                <ArrowBackIcon />
              </span>
              volver
            </Button>
            <Button
              disabled={isLoading}
              onClick={
                () => !isLoading && handleSaveButton().catch(console.error) // eslint-disable-line no-console
              }
            >
              <span slot="start">
                <Save />
              </span>
              Guardar Presupuesto
            </Button>
            {budgetID && (
              <Button disabled={isLoading} onClick={!isLoading && handleDeleteButton} color="rojo" variant="secundario">
                <span slot="start">
                  <DeleteIcon />
                </span>
                Eliminar Presupuesto
              </Button>
            )}
          </Box>
          <Stack display={{ xs: 'flex', sm: 'none' }} spacing={1}>
            <Button
              disabled={isLoading}
              onClick={
                () => !isLoading && handleSaveButton().catch(console.error) // eslint-disable-line no-console
              }
              behaviour="fijo"
            >
              <span slot="start">
                <Save />
              </span>
              Guardar Presupuesto
            </Button>
            {budgetID && (
              <Button
                disabled={isLoading}
                onClick={!isLoading && handleDeleteButton}
                color="rojo"
                behaviour="fijo"
                variant="secundario"
              >
                <span slot="start">
                  <DeleteIcon />
                </span>
                Eliminar Presupuesto
              </Button>
            )}
            <Button onClick={() => navigate('/budget')} behaviour="fijo" variant="texto">
              <span slot="start">
                <ArrowBackIcon />
              </span>
              volver
            </Button>
          </Stack>
        </Box>
        <Box width="38%" display={{ xs: 'none', lg: 'none', xl: 'block' }}>
          <BudgetSummary
            totalIncome={totalIncome}
            totalHome={totalHome}
            totalEntertain={totalEntertain}
            totalFamily={totalFamily}
            totalFinance={totalFinance}
            totalChild={totalChild}
            totalGoal={totalGoal}
          />
        </Box>
        <AlertDialog
          openDialog={openDeleteDialog}
          setOpenDialog={setOpenDeleteDialog}
          header={deleteWarning}
          msg={deleteWarningMsg}
          confirmAction={handleDelete}
          confirmMsg="Eliminar"
        />
      </Box>
    </div>
  );
};

export default BudgetForm;
