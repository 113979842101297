import React, { FC } from 'react';

import './EmailButton.css';

interface EmailLoginButtonProps {
  onClick: () => void;
  isExistingUser: boolean;
}

const EmailLoginButton: FC<EmailLoginButtonProps> = ({ onClick, isExistingUser }) => {
  return (
    <button className="email-btn" onClick={onClick} type="button">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="email-icon">
        <path d="M12 13.065L0 4h24L12 13.065zM12 14.935L24 6v12H0V6l12 8.935z" />
      </svg>
      {isExistingUser ? 'Iniciar sesión con Email' : 'Registrarse con Email'}
    </button>
  );
};

export default EmailLoginButton;
