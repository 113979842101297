import React, { useState, useEffect } from 'react';

// Import CSS for animations
import { Grid } from '@mui/material';

import './ChatSimulation.scss';

const ChatSimulation: React.FC = () => {
  const [messages, setMessages] = useState<{ text: string; sender: string }[]>([]);

  useEffect(() => {
    const conversation = [
      { text: 'Hola, soy Nina! ¿En que te puedo ayudar?', sender: 'assistant' },
      { text: 'Broma?! Otro chatbot con IA??', sender: 'user' },
      { text: 'No soy un bot, soy una pingüina! 🐧 Una experta en finanzas personales', sender: 'assistant' },
      { text: 'Entonces, que #!*#@$ es Relif?', sender: 'user' },
      { text: 'Tu partner financiero en todas...', sender: 'assistant' },
    ];

    let index = 0;
    const messageInterval = 1000; // Time between messages
    const finalMessageTimeout = 3000; // Longer timeout after the final message
    let intervalId: NodeJS.Timeout; // Declare intervalId

    const startConversation = () => {
      intervalId = setInterval(() => {
        if (index < conversation.length) {
          setMessages((prevMessages) => [...prevMessages, conversation[index]]);
          index += 1;
        } else {
          clearInterval(intervalId); // Clear the interval before setting a new timeout
          setTimeout(() => {
            index = 0; // Reset index to start the conversation again
            setMessages([]); // Clear messages to restart
            startConversation(); // Restart the conversation
          }, finalMessageTimeout);
        }
      }, messageInterval);
    };

    startConversation(); // Start the conversation initially

    return () => clearInterval(intervalId); // Use intervalId here
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="chat-simulation">
          <div className="messages">
            {messages.map((message) => (
              <div key={message.text} className={`message ${message.sender}`}>
                <p>{message.text}</p>
              </div>
            ))}
            {/* {isTyping && <div className="typing-indicator">Escribiendo...</div>} */}
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}
      >
        <iframe
          src="https://www.youtube.com/embed/vhyVs_vhHcs?controls=0&showinfo=0&rel=0&modestbranding=1&loop=1"
          title="Chat Simulation"
          className="chat-simulation-video"
        />
      </Grid>
    </Grid>
  );
};

export default ChatSimulation;
