import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography, Tooltip } from '@mui/material';

interface TooltipDefinitionProps {
  label: string;
  term: string;
  definition: string;
  align: string;
}

const TooltipDefinition = ({ label, term, definition, align }: TooltipDefinitionProps) => {
  const tooltipContent = (
    <Box sx={{ p: 1, maxWidth: 300 }}>
      <Typography variant="body2" sx={{ fontStyle: 'italic', mb: 1 }}>
        {term}
      </Typography>
      <Typography variant="body2">{definition}</Typography>
    </Box>
  );

  return (
    <Box display="flex" alignItems="center" gap={1} justifyContent={align === 'right' ? 'flex-end' : 'flex-start'}>
      <Typography variant="body1" color="primary.main" fontSize="16px">
        {label}
      </Typography>
      <Tooltip title={tooltipContent} arrow>
        <InfoOutlinedIcon sx={{ fontSize: 16, color: 'primary.main', cursor: 'help' }} />
      </Tooltip>
    </Box>
  );
};

export default TooltipDefinition;
