import React from 'react';

import { Box, Typography } from '@mui/material';

import TermOptionCard from './TermOptionCard';

const BankClientOfferDetails = ({
  bankOfferId,
  setInterested,
}: {
  bankOfferId: number;
  setInterested: (id: number, months: number) => void;
}) => {
  return (
    <Box>
      <TermOptionCard
        isSelected={false}
        boldTitle="Contáctenme"
        title=""
        amount={0}
        onClick={() => setInterested(bankOfferId, 24)}
      />
      <ul>
        <li>
          <Typography variant="body1" fontSize={16}>
            Al hacer click en el botón, se mandará una solicitud para que un ejecutivo se contacte contigo
          </Typography>
        </li>
        <li>
          <Typography variant="body1" fontSize={16}>
            El ejecutivo te indicará los detalles de la pre aprobación y te dará los pasos a seguir
          </Typography>
        </li>
      </ul>
    </Box>
  );
};

export default BankClientOfferDetails;
