/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PrimaryBtn, { BlackBtn } from 'components/UI/Buttons';
import useDebtFlow from 'hooks/useDebtFlow';
import { useCreateDebtMutation } from 'services/api/debt.api';
import parseCMFDirectDebt from 'utils/parseCMFDirectDebt';
import { captureUserEvent } from 'utils/posthogUtils';

import DebtSummaryTable from './DebtSummaryTable';
import ManualDebtLoop from './ManualDebtLoop';

interface CMFDataInterface {
  onSave: () => void;
}

const CategorizeCMFData: FC<CMFDataInterface> = ({ onSave }) => {
  const [save, setSave] = React.useState(false);
  const debtFlow = useDebtFlow();
  const CMFDebts = debtFlow.CMFResponse ? parseCMFDirectDebt(debtFlow.CMFResponse) : [];
  const manualDebts = debtFlow.debts?.filter((debt) => debt.source === 'Manual') || [];
  const debtList = [
    ...CMFDebts,
    ...manualDebts.map((debt) => ({
      ...debt,
      totalAmount: debt.amount || 0,
      totalMonthlyPayment: debt.monthlyPayment || 0,
    })),
  ];
  const initialDropdown = Array.from({ length: 0 }, () => false);
  initialDropdown[0] = true;

  const [debtCreationFormOpen, setDebtCreationFormOpen] = useState(false);
  const [createDebt] = useCreateDebtMutation();

  const createCMFDebts = async () => {
    const promises = CMFDebts.map((debt) =>
      createDebt({
        institutionName: debt.institutionName || '',
        institutionType: debt.institutionType || 'Banco',
        amount: debt.totalAmount,
        source: debt.source || 'CMF',
        debtType: debt.debtType || 'crédito de consumo',
        currency: 'CLP',
        term: 0,
        monthlyPayment: debt.totalMonthlyPayment,
        interestRate: 0,
        pendingPayments: 0,
        monthsBehind: 0,
      })
    );
    await Promise.all(promises);
  };

  const handleOnSave = async () => {
    await createCMFDebts();
    onSave();
  };

  useEffect(() => {
    if (save) {
      handleOnSave().catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [save]);

  return (
    <Box maxWidth="800px">
      <ManualDebtLoop debtCreationFormOpen={debtCreationFormOpen} setDebtCreationFormOpen={setDebtCreationFormOpen} />
      {!debtCreationFormOpen && (
        <Stack spacing={2}>
          <Typography variant="h3" fontSize="25px" textAlign="center">
            Categorizar deudas
          </Typography>

          {debtList.length > 0 && (
            <div>
              <Typography variant="body1" fontSize="22px" pb="9px" textAlign="left">
                Estas son las deudas vigentes que encontramos a tu nombre. Si falta alguna, agrégala manualmente.
              </Typography>
              <DebtSummaryTable debtList={debtList} />
            </div>
          )}

          {!debtList.length && (
            <Typography variant="body1" fontSize="22px" pb="9px" textAlign="left">
              No encontramos deudas vigentes a tu nombre. Si tienes alguna, agrégala manualmente.
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap={2}
          >
            <BlackBtn
              onClick={() => {
                console.log('open debt creation form'); // eslint-disable-line no-console
                setDebtCreationFormOpen(true);
              }}
              disabled={save}
            >
              Agregar deuda
            </BlackBtn>
            <PrimaryBtn
              type="submit"
              onClick={() => {
                if (!save) {
                  captureUserEvent('ValuableAction: Complete Scanner');
                  setSave(true);
                }
              }}
              disabled={save}
            >
              Continuar
            </PrimaryBtn>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default CategorizeCMFData;
