import React from 'react';

import { Box, Typography } from '@mui/material';

import { BankOffer } from '@relif/backend';
import { captureUserEvent } from 'utils/posthogUtils';

import OfferHeader from './OfferHeader';
import TermOptionCard from './TermOptionCard';

const NewLoanOffer = ({ bankOffer, handleNewLoan }: { bankOffer: BankOffer; handleNewLoan: () => void }) => {
  return (
    <Box sx={{ width: '100%', px: 2 }}>
      <OfferHeader
        title="Monto pre aprobado"
        amountTitle={bankOffer.bank}
        amount={bankOffer.freeAvailabilityAmount || 0}
        amountDescription={
          bankOffer.interestRate ? `${((bankOffer.interestRate || 0) * 100).toFixed(2)}% interés mensual` : ''
        }
      />
      <Box mt={2} width="100%">
        <TermOptionCard
          isSelected={false}
          boldTitle="Simular crédito"
          title=""
          amount={0}
          onClick={() => {
            captureUserEvent('BankOfferFlow: User confirmed interest in a New Loan offer option', {
              bank: bankOffer.bank,
              bankOfferUuid: bankOffer.uuid,
            });
            handleNewLoan();
          }}
        />
        <ul>
          <li>
            <Typography variant="body1" fontSize={16}>
              {bankOffer.bank}, te puede otorgar un nuevo crédito por el monto indicado.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" fontSize={16}>
              Puedes solicitar un monto menor.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" fontSize={16}>
              Al hacer click en el botón. Se te redirigirá a la página de {bankOffer.bank} para que puedas completar la
              solicitud
            </Typography>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export default NewLoanOffer;
