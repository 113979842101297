import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import priceFormat from 'services/format/priceFormat';

interface Props {
  title: string;
  amountTitle: string;
  amount: number;
  amountDescription: string;
  alternativeText?: string;
}

const OfferHeader: FC<Props> = ({ title, amountTitle, amount, amountDescription, alternativeText }) => {
  return (
    <Box sx={{ border: '1px solid', borderColor: '#9FC6EA', borderRadius: 4, pb: '100px', width: '100%' }}>
      <Box
        id="header"
        sx={{
          backgroundColor: '#9FC6EA',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: 2,
          px: 3,
          borderRadius: 4,
          height: '100px',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            width: '100%',
            mt: '15px',
            fontSize: 22,
            fontWeight: 600,
            color: 'primary.main',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            fontSize: 18,
            width: '100%',
            mt: 1,
            fontWeight: 400,
            color: 'primary.main',
            textAlign: 'center',
          }}
        >
          {amountTitle}
        </Typography>
        <Box
          sx={{
            backgroundColor: 'secondary.main',
            borderRadius: '20px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            height: '100px',
            mt: 1.5,
            mb: '-75px',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: 22,
              width: '100%',
              fontWeight: 600,
              color: 'primary.main',
              textAlign: 'center',
            }}
          >
            {amount ? priceFormat.format(amount) : alternativeText}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: 16,
              width: '100%',
              mt: 1,
              fontWeight: 400,
              color: 'primary.main',
              textAlign: 'center',
            }}
          >
            {amountDescription}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

OfferHeader.defaultProps = {
  alternativeText: '',
};

export default OfferHeader;
