import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { checkIfTokenIsValid } from 'authStorage';
import PrimaryBtn from 'components/UI/Buttons';
import useAccessToken from 'hooks/useAccessToken';
import useDebtFlow from 'hooks/useDebtFlow';
import useQuery from 'hooks/useQuery';
import { useLazyListDebtsQuery } from 'services/api/debt.api';
import { useLazyGetIsUserValidatedQuery } from 'services/api/firmaYa.api';
import { useLazyGetLast30DaysCMFRequestQuery, useLazyGetLastCMFRequestQuery } from 'services/api/floid.api';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';
import { dateFormat } from 'services/format/dateFormat';
import { BankOffer } from 'services/types/bankOffers';
import { Origin } from 'services/types/origin';
import { captureUserEvent } from 'utils/posthogUtils';

import ClaveUnicaDebtForm from './components/ClaveUnicaDebtForm';
import CreatePassword from './components/CreatePassword';
import RedirectToDebtsModal from './components/RedirectToDebtsModal';
import SetRutPhoneAndEmail from './components/SetRutPhoneAndEmail';
import SignMandateAndCreateUser from './components/SignMandateAndCreateUser';

const UniqueDebtFlow = () => {
  const path = window.location.href;
  let origin: Origin = 'UniqueFlow';
  if (path.includes('banco-chile')) {
    origin = 'Banco de Chile';
  } else if (path.includes('caja-los-andes') || path.includes('caja%20los%20andes%20')) {
    origin = 'Caja Los Andes';
  }
  const debtFlow = useDebtFlow();
  const navigate = useNavigate();
  const query = useQuery();
  const redirectUrl = query.get('redirectUrl');
  const token = useAccessToken();

  const [getCurrentUser, userQuery] = useLazyGetUserInformationQuery();
  const currentUser = userQuery.data;
  const externalProviderId = currentUser?.externalProviderId;
  const [triggerIsUserValidated, isUserValidatedResult] = useLazyGetIsUserValidatedQuery();
  const [triggerCMF, last30CMFResult] = useLazyGetLast30DaysCMFRequestQuery();
  const [triggerLastCMFRequest] = useLazyGetLastCMFRequestQuery();
  const [triggerDebts, debtsResult] = useLazyListDebtsQuery();
  const [instantBankOffer, setInstantBankOffer] = useState<BankOffer | null>(null);
  const [isFlowSuccess, setIsFlowSuccess] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [rut, setRut] = useState('');
  const [phone, setPhone] = useState('+56 (9) ');
  const [email, setEmail] = useState('');

  const lastCMFRequestDate = debtFlow.lastCMFRequestDate ? new Date(debtFlow.lastCMFRequestDate) : null;

  if (query.get('uniqueFlowRedirect') || query.get('rut')) {
    window.history.pushState({}, document.title, window.location.pathname); // reload all queries to see what step should the user be redirected to
  }

  const getIsDebtAssignmentComplete = () => {
    if (!last30CMFResult.data || !debtsResult.data) return false;
    const CMFDebt = debtsResult.data.find((debt) => debt.source?.includes('CMF'));
    return (
      (last30CMFResult.data.data.directDebt.length > 0 && CMFDebt !== undefined) ||
      last30CMFResult.data.data.directDebt.length === 0
    );
  };

  const handleSuccessRedirect = () => {
    if (instantBankOffer) {
      navigate(`/bank-offers/${instantBankOffer.uuid}?redirectUrl=${redirectUrl || '/debt'}`);
    } else if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate('/debt');
    }
  };

  useEffect(() => {
    if (checkIfTokenIsValid(token)) {
      getCurrentUser(null).catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [token]);

  useEffect(() => {
    if (isUserValidatedResult.data?.isValidated) {
      // eslint-disable-next-line no-console
      triggerLastCMFRequest(null).catch((e) => console.error(e));
      if (!last30CMFResult.isFetching) {
        // eslint-disable-next-line no-console
        triggerCMF(null).catch((e) => console.error(e));
      }
      if (!debtsResult.isFetching) {
        // eslint-disable-next-line no-console
        triggerDebts(null).catch((e) => console.error(e));
      }
    }
  }, [isUserValidatedResult.data]);

  useEffect(() => {
    if (currentUser && currentUser.id !== 0) {
      if (!isUserValidatedResult.isFetching) {
        // eslint-disable-next-line no-console
        triggerIsUserValidated(null).catch((e) => console.error(e));
      }
      if (currentUser.origin === null) {
        setRut(currentUser.rut || '');
        setPhone(currentUser.phone || '');
        setEmail(currentUser.email || '');
        origin = null;
      }
    }
  }, [currentUser]);

  useEffect(() => {
    // only change step when user is logged in, all the queries are loaded and the component is being initialized (activeStep === 0)
    if (
      currentUser &&
      currentUser.id !== 0 &&
      !isUserValidatedResult.isFetching &&
      !last30CMFResult.isFetching &&
      !debtsResult.isFetching &&
      isUserValidatedResult.data !== undefined &&
      last30CMFResult.data !== undefined &&
      debtsResult.data !== undefined &&
      activeStep === 0
    ) {
      if (!isUserValidatedResult.data.isValidated) {
        setActiveStep(1);
      } else if (last30CMFResult.data === null) {
        setActiveStep(2);
      } else if (!getIsDebtAssignmentComplete()) {
        setActiveStep(3);
      } else if (getIsDebtAssignmentComplete() && currentUser.origin !== null) {
        setActiveStep(4);
      } else {
        handleSuccessRedirect();
      }
    }
  }, [isUserValidatedResult.data, last30CMFResult.data, debtsResult.data, currentUser, instantBankOffer]);

  useEffect(() => {
    if (isFlowSuccess) {
      handleSuccessRedirect();
    }
  }, [isFlowSuccess]);

  if (last30CMFResult.data !== null && lastCMFRequestDate) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h6">Completaste el escáner financiero el recientemente</Typography>
        <Typography variant="body1">
          Podrás completarlo nuevamente a partir del{' '}
          {dateFormat(new Date(lastCMFRequestDate.getTime() + 30 * 24 * 60 * 60 * 1000))}
        </Typography>
        <PrimaryBtn onClick={() => navigate('/')} sx={{ mt: 2 }}>
          Volver
        </PrimaryBtn>
      </Box>
    );
  }

  if (activeStep === 0) {
    return (
      <SetRutPhoneAndEmail
        onSuccess={() => {
          captureUserEvent('Unique Debt flow: Set phone, rut and email');
          setActiveStep(1);
        }}
        origin={origin}
        rut={rut}
        setRut={setRut}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
        externalProviderId={currentUser?.externalProviderId || undefined}
        redirectUrl={redirectUrl || undefined}
      />
    );
  }
  if (activeStep === 1) {
    return (
      <SignMandateAndCreateUser
        onSuccess={() => {
          captureUserEvent('Unique Debt flow: Mandate Signed');
          setActiveStep(2);
        }}
        origin={origin}
        rut={rut}
        phone={phone}
        email={email}
        externalProviderId={externalProviderId || undefined}
        redirectUrl={redirectUrl || undefined}
      />
    );
  }
  if (activeStep === 2) {
    return (
      <RedirectToDebtsModal
        onRedirectClick={() => {
          captureUserEvent('Unique Debt flow: Redirected to debts modal');
          setActiveStep(3);
        }}
        origin={origin}
      />
    );
  }
  if (activeStep === 3) {
    return (
      <ClaveUnicaDebtForm
        onSuccess={() => {
          captureUserEvent('Unique Debt flow: Successful clave unica');
          setIsFlowSuccess(true);
        }}
        origin={origin}
        setInstantBankOffer={setInstantBankOffer}
      />
    );
  }
  if (activeStep === 4) {
    return (
      <CreatePassword
        onSuccess={() => {
          captureUserEvent('Unique Debt flow: User added a password');
          navigate('/debt');
        }}
        origin={origin}
        email={email}
      />
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default UniqueDebtFlow;
