import React, { FC } from 'react';

import { Box } from '@mui/material';

import styles from './ChatWrapper.module.scss';

interface Props {
  children: JSX.Element;
}

const ChatWrapper: FC<Props> = ({ children }: Props) => {
  return (
    <Box className={styles.Link}>
      <a href="/chat" target="_blank" rel="noreferrer">
        {children}
      </a>
    </Box>
  );
};

export default ChatWrapper;
