import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { Theme, ThemeProvider } from '@mui/material/styles';

import { CloseIcon } from 'assets/icons';
import { checkIfTokenIsValid } from 'authStorage';
import Banner from 'components/UI/Banner';
import Sidebar, { drawerWidth } from 'components/layout/SideBar/Sidebar';
import useAccessToken from 'hooks/useAccessToken';
import useCurrentRoute from 'hooks/useCurrentRoute';
import useIsCajaLosAndesTheme from 'hooks/useIsCajaLosAndesTheme';
import useIsMobile from 'hooks/useIsMobile';
import { useFeatureFlagEnabled, useFeatureFlagPayload } from 'posthog-js/react';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { useSignOutAsUserMutation } from 'services/api/admin-users.api';
import { useLazyGetUserInformationQuery, useLazyGetUserQuery } from 'services/api/user.api';
import trpc from 'trpc';

import { BottomNavBar } from './BottomNavBar';
import ChatButton from './ChatButton';
import styles from './Layout.module.scss';
import CajaLosAndesTheme from './themes/CajaLosAndesTheme';
import DefaultTheme from './themes/DefaultTheme';

declare module '@mui/material/styles' {
  interface Palette {
    newBlue: Palette['primary'];
    backgroundGrey: Palette['primary'];
  }
  interface PaletteOptions {
    newBlue: PaletteOptions['primary'];
    backgroundGrey: PaletteOptions['primary'];
  }
}

const THEMES: Theme[] = [DefaultTheme, CajaLosAndesTheme];

const setThemeXLTypographySize = (theme: Theme): void => {
  // eslint-disable-next-line no-param-reassign
  theme.typography.h2[theme.breakpoints.up('xl')] = {
    fontSize: '26px',
  };
  // eslint-disable-next-line no-param-reassign
  theme.typography.h3[theme.breakpoints.up('xl')] = {
    fontSize: '22px',
  };
  // eslint-disable-next-line no-param-reassign
  theme.typography.h4[theme.breakpoints.up('xl')] = {
    fontSize: '21px',
  };
};

THEMES.forEach(setThemeXLTypographySize);

interface Props {
  children?: JSX.Element;
}

const Layout: FC<Props> = ({ children }: Props) => {
  const [openLoggedInAlert, setOpenLoggedInAlert] = useState(false);
  const [show, setShow] = useState(true);
  const { private: privateRoute, noLayout, disableChatButton } = useCurrentRoute();
  const isCajaLosAndesTheme = useIsCajaLosAndesTheme();
  const accessToken = useAccessToken();
  const theme = THEMES[isCajaLosAndesTheme ? 1 : 0];

  const [signOutAsUser, { data }] = useSignOutAsUserMutation();
  const bannerQuery = trpc.banners.getBanner.useMutation();
  const registerBanner = trpc.banners.registerBanner.useMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [getOtherUser, otherUser] = useLazyGetUserQuery();
  const [getUser, user] = useLazyGetUserInformationQuery();

  const isFinancialAdvisorUser = user?.data?.isFinancialAdvisorUser;
  const { isMobile } = useIsMobile();

  const isMobileFinancialAdvisorUser = isMobile && isFinancialAdvisorUser;
  const handleSignOutAsUser = async () => {
    await signOutAsUser(null).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error', error);
    });
  };

  const showLoggedInAlert = useFeatureFlagEnabled('logged_in_alert');
  const loggedInAlertPayload = useFeatureFlagPayload('logged_in_alert') as {
    message: string | null;
    redirectMessage: string | null;
    redirectUrl: string | null;
  };

  const showBanner = show && bannerQuery.data && typeof bannerQuery.data !== 'string' && !bannerQuery.data.isRegistered;

  useEffect(() => {
    if (showLoggedInAlert) {
      setOpenLoggedInAlert(true);
    }
  }, [showLoggedInAlert]);

  useEffect(() => {
    if (data) {
      dispatch(setAccessToken({ accessToken: data.accessToken, loggedInAsOtherUser: false }));
    }
  }, [data]);

  useEffect(() => {
    if (checkIfTokenIsValid(accessToken)) {
      getOtherUser(null).catch((e) => console.error(e)); // eslint-disable-line no-console
      getUser(null).catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [accessToken]);

  useEffect(() => {
    if (otherUser.isSuccess) {
      bannerQuery.mutate();
    }
  }, [otherUser]);

  if (!privateRoute || noLayout) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={styles.ComponentContainer}>{children}</div>
        {!isFinancialAdvisorUser && !disableChatButton && <ChatButton />}
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: { xs: 'block', md: 'flex' } }}>
        {!isMobileFinancialAdvisorUser && <Sidebar />}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: {
              xs: '100%',
              md: `calc(100% - ${drawerWidth}px)`,
            },
            m: 0,
            p: 0,
            maxHeight: !isMobileFinancialAdvisorUser ? '100vh' : `calc(100vh - ${20}px)`,
          }}
        >
          {accessToken && !!accessToken.loggedInAsOtherUser && otherUser.data && (
            <Alert severity="error">
              Iniciaste sesión como {otherUser.data.firstName} {otherUser.data.lastName} ID: {otherUser.data.id}
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                onClick={() => {
                  handleSignOutAsUser().catch((e) => {
                    // eslint-disable-next-line no-console
                    console.error(e);
                  });
                }}
                sx={{ ml: 2 }}
              >
                Cerrar sesión como este usuario
              </Button>
            </Alert>
          )}
          {openLoggedInAlert && loggedInAlertPayload && (
            <Alert
              severity="info"
              sx={{
                zIndex: 9999,
                backgroundColor: 'info.light',
                color: 'info.main',
              }}
              action={
                <IconButton
                  onClick={() => {
                    setOpenLoggedInAlert(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <AlertTitle>{loggedInAlertPayload.message}</AlertTitle>
              {loggedInAlertPayload.redirectMessage && loggedInAlertPayload.redirectUrl && (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    navigate(loggedInAlertPayload.redirectUrl || '/');
                    setOpenLoggedInAlert(false);
                  }}
                  sx={{ ml: 2 }}
                >
                  {loggedInAlertPayload.redirectMessage}
                </Button>
              )}
            </Alert>
          )}

          {show && bannerQuery.data && typeof bannerQuery.data !== 'string' && !bannerQuery.data.isRegistered && (
            <Banner
              title={bannerQuery.data.banner.title}
              description={bannerQuery.data.banner.description}
              buttonText={bannerQuery.data.banner.buttonText}
              onConfirmationClick={() => {
                if (typeof bannerQuery.data !== 'string') {
                  registerBanner.mutate({ bannerId: bannerQuery.data.banner.id });
                }
              }}
              show={show}
              setShow={setShow}
            />
          )}
          <Box
            sx={{
              backgroundColor: 'backgroundGrey.main',
              borderRadius: { xs: 0, md: '38px 0 0 30px' },
              minHeight: '100vh',
              maxHeight: '100vh',
              overflow: 'scroll',
              px: { xs: 1, lg: 3, xl: 6 },
              width: '100%',
              pt: { xs: showBanner ? 0 : '80px', lg: 0 },
            }}
          >
            {children}
          </Box>
          {!isFinancialAdvisorUser && !disableChatButton && <ChatButton />}
        </Box>
        {isMobileFinancialAdvisorUser && <BottomNavBar />}
      </Box>
    </ThemeProvider>
  );
};

Layout.defaultProps = {
  children: undefined,
};

export default Layout;
