import React, { FC } from 'react';

import Box from '@mui/material/Box';

import Footer from 'pages/LandingPageNew/components/Footer';
import Navigation from 'pages/LandingPageNew/components/Navigation';

import Article from './Article';

const LandingPageArticle: FC = () => {
  return (
    <Box>
      <Navigation isBlog openSection="blog" handleOpenFinancialAdvisorAuthModal={() => {}} setOpenSection={() => {}} />
      <Box sx={{ padding: { lg: '120px 30px 150px', xs: '110px 10px 0px' } }}>
        <Article />
      </Box>
      <Footer />
    </Box>
  );
};

export default LandingPageArticle;
