import React from 'react';

import Box from '@mui/material/Box';

import Footer from 'pages/LandingPageNew/components/Footer';
import Navigation from 'pages/LandingPageNew/components/Navigation';

import HighLights from './components/HighLights';

const LandingPageBlog = () => {
  return (
    <>
      <Navigation isBlog openSection="blog" handleOpenFinancialAdvisorAuthModal={() => {}} setOpenSection={() => {}} />
      <Box sx={{ padding: { lg: '120px 30px 0px', xs: '110px 10px 0px' } }}>
        <HighLights />
      </Box>
      <Footer />
    </>
  );
};

export default LandingPageBlog;
