import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import SectionHeader from 'components/layout/SectionHeader';
import { useGetUserInformationQuery } from 'services/api/user.api';

import ActionPlan from './components/ActionPlan';
import GoalsSection from './components/goals';

const MyPlan = () => {
  const [goalsRefetchFlag, setGoalsRefetchFlag] = React.useState(false);
  const { data: userInfo } = useGetUserInformationQuery(null);

  const handleGoalsRefetch = () => {
    setGoalsRefetchFlag(!goalsRefetchFlag);
  };

  return (
    <>
      <SectionHeader text="Mi plan" button={null} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} md={6} p={1}>
            <GoalsSection handleGoalsRefetch={handleGoalsRefetch} />
          </Grid>
          {userInfo?.company?.subscriptionType !== 'free' && (
            <Grid item xs={12} md={6} p={1}>
              <ActionPlan />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default MyPlan;
