import React, { FC } from 'react';

import FinancialAdvisorAuthModal from 'components/common/FinancialAdvisorAuthModal';

const RELIF_FINANCIAL_ADVISOR_COMPANY_ID = 59;

const SignIn: FC = () => {
  return (
    <FinancialAdvisorAuthModal
      open
      onClose={() => {}}
      companyId={RELIF_FINANCIAL_ADVISOR_COMPANY_ID}
      defaultIsExistingUser
    />
  );
};

export default SignIn;
