import { BankOffer } from '@relif/backend';

import baseApi from './baseApi';

const bankOfferApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBankOffers: build.query({
      query: () => ({
        url: `/bankOffers`,
        method: 'GET',
      }),
      transformResponse: (response: BankOffer[] | null) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetBankOffersQuery } = bankOfferApi;

export default bankOfferApi;
