import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import { checkIfTokenIsValid } from 'authStorage';
import FinancialAdvisorAuthModal from 'components/common/FinancialAdvisorAuthModal';
import useAccessToken from 'hooks/useAccessToken';
import useDebtFlow from 'hooks/useDebtFlow';
import { useLazyGetLastCMFRequestQuery } from 'services/api/floid.api';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';

import ChatSimulation from './components/ChatSimulation';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Header from './components/Header';
import Navigation from './components/Navigation';
import RefiRobot from './components/RefiRobot';
import WhoWeAre from './components/WhoWeAre';

const RELIF_FINANCIAL_ADVISOR_COMPANY_ID = 59;
const SOURCE = 'B2C-LANDING-PAGE';

const FINANCIAL_ADVISORS_DEFAULT_REDIRECT_URL = '/chat';
const NON_FINANCIAL_ADVISORS_DEFAULT_REDIRECT_URL = '/';

const App = () => {
  const [openSection, setOpenSection] = useState<null | string>(null);
  const [openFinancialAdvisorAuthModal, setOpenFinancialAdvisorAuthModal] = useState(false);
  const token = useAccessToken();
  const tokenIsValid = checkIfTokenIsValid(token);
  const navigate = useNavigate();
  const debtFlow = useDebtFlow();
  const [getUserInformation, { data: userInformation }] = useLazyGetUserInformationQuery();
  const [getLastCMFRequest, { isSuccess: isLastCMFRequestSuccess }] = useLazyGetLastCMFRequestQuery();
  const [isExistingUser, setIsExistingUser] = useState(false);

  const handleOpenFinancialAdvisorAuthModal = (defaultIsExistingUser: boolean) => {
    setIsExistingUser(defaultIsExistingUser);
    setOpenFinancialAdvisorAuthModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [openSection]);
  // Fetch user information once the user is logged in
  useEffect(() => {
    if (tokenIsValid) {
      getUserInformation(null).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching user information');
      });
    }
  }, [tokenIsValid, token]);

  // Fetch CMF request once the user information is fetched
  useEffect(() => {
    // CMF information can only be fetched if the user has a rut
    if (userInformation && userInformation.rut) {
      getLastCMFRequest(null).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching CMF request last 30 days');
      });
    }
    // If the user does not have a rut, redirect to the onboarding page
    if (userInformation && !userInformation.rut) {
      if (userInformation?.isFinancialAdvisorUser) {
        navigate(`/onboarding/relif?redirectUrl=${FINANCIAL_ADVISORS_DEFAULT_REDIRECT_URL}`);
      } else {
        navigate(`/onboarding/relif?redirectUrl=${NON_FINANCIAL_ADVISORS_DEFAULT_REDIRECT_URL}`);
      }
    }
  }, [userInformation]);

  // Redirect to the chat page if the user has a CMF response in the last 30 days
  useEffect(() => {
    if (!isLastCMFRequestSuccess) return;
    // If the user has a CMF response, redirect to the chat or home page
    if (debtFlow.CMFResponse) {
      if (userInformation?.isFinancialAdvisorUser) {
        navigate(FINANCIAL_ADVISORS_DEFAULT_REDIRECT_URL);
      } else {
        navigate(NON_FINANCIAL_ADVISORS_DEFAULT_REDIRECT_URL);
      }
    } else if (!debtFlow.CMFResponse) {
      // If the user does not have a CMF response, redirect to the onboarding page
      if (userInformation?.isFinancialAdvisorUser) {
        navigate(`/onboarding/relif?redirectUrl=${FINANCIAL_ADVISORS_DEFAULT_REDIRECT_URL}`);
      } else {
        navigate(`/onboarding/relif?redirectUrl=${NON_FINANCIAL_ADVISORS_DEFAULT_REDIRECT_URL}`);
      }
    }
  }, [debtFlow.CMFResponse, isLastCMFRequestSuccess]);

  return (
    <Box sx={{ backgroundColor: '#FBFCFE', zoom: '100%' }}>
      <FinancialAdvisorAuthModal
        open={openFinancialAdvisorAuthModal}
        onClose={() => setOpenFinancialAdvisorAuthModal(false)}
        companyId={RELIF_FINANCIAL_ADVISOR_COMPANY_ID}
        defaultIsExistingUser={isExistingUser}
        source={SOURCE}
      />
      <Navigation
        openSection={openSection}
        setOpenSection={setOpenSection}
        handleOpenFinancialAdvisorAuthModal={handleOpenFinancialAdvisorAuthModal}
      />
      {openSection === null && (
        <>
          <Header handleOpenFinancialAdvisorAuthModal={handleOpenFinancialAdvisorAuthModal} />
          <ChatSimulation />
          <RefiRobot handleOpenFinancialAdvisorAuthModal={handleOpenFinancialAdvisorAuthModal} />
        </>
      )}
      {openSection === 'WhoWeAre' && <WhoWeAre />}
      {openSection === 'ContactUs' && <ContactUs />}
      <Footer />
    </Box>
  );
};

export default App;
