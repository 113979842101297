import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material/styles';

import { PrimaryBtn } from 'components/UI/Buttons';
import { captureUserEvent } from 'utils/posthogUtils';

const LinkButton = styled(Button)(({ theme }) => ({
  color: 'white',
  fontSize: '17px',
  fontFamily: 'Roboto',
  underline: 'none',
  paddingLeft: '0px',
  paddingRight: '0px',
  marginLeft: '8px',
  marginRight: '8px',
  display: 'block',
  textAlign: 'start',
  '&:hover': {
    backgroundColor: 'none',
  },
  '&:before': {
    content: "''",
    position: 'absolute',
    width: '0px',
    height: '3px',
    bottom: '8px',
    left: '0',
    transform: 'translate(0,100%)',
    backgroundColor: theme.palette.secondary.main,
    transformOrigin: 'center',
    visibility: 'hidden',
    transition: 'all 0.2s',
  },
  '&:hover:before': {
    visibility: 'visible',
    width: '100%',
  },
}));

const BlueSeparator = () => (
  <svg
    width="80%"
    preserveAspectRatio="none"
    height="2"
    viewBox="0 0 180 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 1.00003L180 1.00005" stroke="#638DB8" strokeWidth="2" />
  </svg>
);

interface Props {
  openSection: null | string;
  setOpenSection: (v: null | string) => void;
  isBlog?: boolean;
  handleOpenFinancialAdvisorAuthModal: (isExistingUser: boolean) => void;
}

const Navigation: FC<Props> = ({ openSection, setOpenSection, isBlog, handleOpenFinancialAdvisorAuthModal }) => {
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppBar
      sx={{
        variant: 'h2',
        backgroundColor: openSection === null && scrollPosition < 10 ? 'transparent' : '#15183A',
        boxShadow: 'none',
      }}
    >
      <Container>
        <Box my={1} display="flex" justifyContent="space-between">
          <Box display="flex">
            <Box sx={{ cursor: 'pointer' }} onClick={() => setOpenSection(null)}>
              <img
                src="/relif_logo_white.webp"
                alt="Logo"
                style={{
                  maxWidth: '200px',
                }}
              />
            </Box>
            <Box display={{ xs: 'none', md: 'flex' }} alignItems="center" pl={{ md: 0, lg: 4 }}>
              {isBlog ? (
                <>
                  <LinkButton onClick={() => navigate('/welcome')}>Inicio</LinkButton>
                  <LinkButton onClick={() => navigate('/blog')}>Blog</LinkButton>
                </>
              ) : (
                <>
                  <LinkButton onClick={() => setOpenSection('WhoWeAre')}>Quiénes somos</LinkButton>
                  <LinkButton onClick={() => setOpenSection('ContactUs')}>Contáctanos</LinkButton>
                  <LinkButton href="https://www.zeller.ai">Zeller by Relif</LinkButton>
                </>
              )}
            </Box>
          </Box>
          <Box display={{ xs: 'none', md: 'flex' }}>
            <Box justifyContent="center" alignItems="center" gap={2} display="flex">
              {!isBlog && (
                <PrimaryBtn
                  onClick={() => {
                    captureUserEvent('CaughtAttention: First login attempt');
                    handleOpenFinancialAdvisorAuthModal(true);
                  }}
                >
                  Iniciar sesión
                </PrimaryBtn>
              )}
            </Box>
          </Box>
          <Box display={{ xs: 'flex', md: 'none' }}>
            <IconButton onClick={() => setOpenMenu(true)}>
              <MenuIcon sx={{ color: 'white', fontSize: '32px' }} />
            </IconButton>
            <SwipeableDrawer
              open={openMenu}
              onClose={() => setOpenMenu(false)}
              onOpen={() => setOpenMenu(true)}
              anchor="right"
            >
              <Stack spacing={2} alignItems="center" py={8} px={3}>
                <Button
                  variant="text"
                  onClick={() => {
                    setOpenSection('WhoWeAre');
                    setOpenMenu(false);
                  }}
                  sx={{
                    fontFamily: 'Jost',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '25px',
                  }}
                >
                  Quiénes somos
                </Button>
                <BlueSeparator />
                <Button
                  variant="text"
                  onClick={() => {
                    setOpenSection('ContactUs');
                    setOpenMenu(false);
                  }}
                  sx={{
                    fontFamily: 'Jost',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '25px',
                  }}
                >
                  Contáctanos
                </Button>
                <BlueSeparator />
                <BlueSeparator />
                <Button
                  variant="text"
                  href="https://www.zeller.ai"
                  rel="noreferrer"
                  target="_blank"
                  sx={{
                    fontFamily: 'Jost',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '25px',
                  }}
                >
                  Zeller by Relif
                </Button>
                <BlueSeparator />
                {!isBlog && (
                  <PrimaryBtn
                    variant="outlined"
                    sx={{
                      color: 'black',
                      backgroundColor: '#F9D00A',
                      borderColor: '#F9D00A',
                      '&:hover': {
                        backgroundColor: '#DCB705',
                        borderColor: '#DCB705',
                        color: 'black',
                      },
                    }}
                    onClick={() => {
                      captureUserEvent('CaughtAttention: First login attempt');
                      handleOpenFinancialAdvisorAuthModal(true);
                      setOpenMenu(false);
                    }}
                  >
                    Iniciar sesión
                  </PrimaryBtn>
                )}
              </Stack>
            </SwipeableDrawer>
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
};

Navigation.defaultProps = {
  isBlog: false,
};

export default Navigation;
