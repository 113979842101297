import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { useRollbarPerson } from '@rollbar/react';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import useAccessToken from 'hooks/useAccessToken';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';

import { checkIfTokenIsValid } from '../authStorage';
import routes, { Route as RouteType } from './routes';

const getValidRedirectUrl = (redirectUrl: string) => {
  // Split only on the first occurrence of '?' to preserve other query params
  const [pathPart, queryPart] = redirectUrl.split(/\?(.+)/);
  const redirectUrlPath = pathPart;
  const validRedirectUrl = Object.values(routes).some((routeValue) => routeValue.path === redirectUrlPath);

  // If valid path and there are query params, reconstruct properly
  if (validRedirectUrl && queryPart) {
    return `${redirectUrlPath}?${queryPart}`;
  }

  // If valid path but no query params, return just the path
  if (validRedirectUrl) {
    return redirectUrlPath;
  }

  return '/';
};

interface Props {
  route: RouteType;
}

const AuthRoute: FC<Props> = ({ route }) => {
  const {
    component: Component,
    path,
    private: privateRoute,
    isFinancialAdvisorExclusive,
    isNonFinancialAdvisorExclusive,
  } = route;
  const [getUser, user] = useLazyGetUserInformationQuery();
  const token = useAccessToken();
  const tokenIsValid = checkIfTokenIsValid(token);
  const dispatch = useDispatch();
  // get accessToken from query params
  const urlParams = new URLSearchParams(window.location.search);
  const queryToken = urlParams.get('accessToken');

  useRollbarPerson({
    id: user?.data?.id || '',
    email: user?.data?.email || '',
    rut: user?.data?.rut || '',
    username: user.data ? `${user.data.firstName} ${user.data.lastName}` : '',
    firstName: user?.data?.firstName || '',
    lastName: user?.data?.lastName || '',
  });

  useEffect(() => {
    if (tokenIsValid) {
      getUser(null).catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [tokenIsValid]);

  // set access token from query params if the current token is not valid
  useEffect(() => {
    if (queryToken && !tokenIsValid) {
      dispatch(setAccessToken({ accessToken: queryToken }));
    }
  }, [queryToken]);

  // show loading overlay while setting access token
  if (!token && queryToken) {
    return <LoadingOverlay visible />;
  }

  if (tokenIsValid && (path === '/sign-in' || path === '/sign-up')) {
    // Adds the text after '?redirect=/'
    if (window.location.search.includes('referrer=')) {
      return <Navigate to="/" />;
    }
    const redirectUrl = `/${window.location.search.slice(11)}`;
    return <Navigate to={getValidRedirectUrl(redirectUrl)} />;
  }
  if (privateRoute && !tokenIsValid && path === '/') {
    return <Navigate to="/welcome" />;
  }
  if (privateRoute && !tokenIsValid) {
    const signInPath = `/sign-in?redirect=${window.location.pathname}${window.location.search}`;
    return <Navigate to={signInPath} />;
  }
  if (path === '/admin' && user.data && !user.data.isAdmin) {
    return <Navigate to="/unauthorized" />;
  }
  if (isFinancialAdvisorExclusive && user.data && !user.data.isFinancialAdvisorUser) {
    return <Navigate to="/" />;
  }
  if (isNonFinancialAdvisorExclusive && user.data && user.data.isFinancialAdvisorUser) {
    return <Navigate to="/chat" />;
  }
  return <Component />;
};

export default AuthRoute;
